/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createEntityReference, getEntity } from 'schemas/state';
import Button from 'components/Button';
import useIsMobile from 'hooks/browser/useIsMobile';

import isInExternalEnvironment from 'actions/app/isInExternalEnvironment';
import {
  getCoordinatorFailedReason,
  isCoordinatorLoading
} from 'actions/coordinator';
import { makeApiCall } from 'actions/references';
import {
  createRegistrationFlow,
  getRegistrationResult,
  RegistrationReferenceName
} from 'actions/registration';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';

import RegistrationApi from 'api/registration';

import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import { TermsOfUse, PaymentTerms, PrivacyPolicy } from 'config/messageLinks';

import VerifyAccount from 'containers/Registration/VerifyAccount';

import { LoginCoordinatorName } from 'config/constants';

import { useRegistrationMetadata } from 'hooks/registration';
import Currency from 'components/Currency';

import Messages, { ErrorMessages } from './index.messages';
import RegistrationForm from './Form';

const RegistrationErrorNotification = ({ error, style }) => {
  if (!error) return <></>;

  const ErrorMessage = ErrorMessages[error] || ErrorMessages.Default;
  return (
    <div style={style}>
      <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
        <ErrorMessage.Message />
      </SmallBannerNotification>
    </div>
  );
};

const registerCompanyAccount = ({
  companyName,
  isPersonalAccount,
  email,
  phone,
  firstName,
  lastName,
  password,
  address,
  token
}) => createRegistrationFlow((dispatch, getState) => {
  dispatch(makeApiCall(
    RegistrationReferenceName,
    RegistrationApi.registerCompanyWithToken,
    {
      secureToken: token,
      companyName,
      isPersonalAccount,
      emailAddress: email,
      phoneNumber: phone,
      firstName,
      lastName,
      password,
      address
    },
    {
      extractFirstErrorMessage: true
    }
  ));
});

const connectSelector = (state) => {
  const {
    error
  } = state.registration;
  const isRegistering = isCoordinatorLoading(state, LoginCoordinatorName.Register);
  const { encryptedUserId, isRegistered, isVerified } = getRegistrationResult(state) || {};

  return {
    coordinatorError: getCoordinatorFailedReason(state, LoginCoordinatorName.Register),
    encryptedUserId,
    error,
    inExternalTestEnvironment: isInExternalEnvironment(state),
    isRegistered,
    isVerified,
    submitLoading: isRegistering
  };
};

function Intro({
  metadata,
  tokenData,
  onClick
}) {
  const isMobile = useIsMobile();
  let intent = tokenData?.intent;
  let companyName = metadata?.from?.companyName;
  let title = 'Company Registration';
  let message = <div>
    Register your company to view and manage invoices and payments with {companyName}.
  </div>;
  let actionLabel = 'Register';
  if (intent === 'FreeAch' || intent === 'AchPlus') {
    title = 'Electronic Payment Registration';
    message = <div>
      Register to receive and manage electronic payments from {companyName}.
      If you are already receiving electronic payments from this customer,
      you will be able to confirm your payment method during the account setup process.
    </div>;
    actionLabel = 'Register for Electronic Payment';
  }

  return (
    <div style={{ paddingTop: '56px' }}>
      <HeaderText>
        {title}
      </HeaderText>
      <HeaderText variant={HeaderTextVariants.Small} style={{ paddingTop: '14px' }}>
        {message}
      </HeaderText>

      <div style={{
        marginTop: '23px',
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '2px',
        padding: '20px',
        display: isMobile ? 'block' : 'table',
        width: '100%',
        verticalAlign: 'top'
      }}>
        <div style={{ display: isMobile ? 'block' : 'table-cell' }}>
          <div className="vp-label">
            Customer
          </div>
          <div>
            <strong>{companyName}</strong>
          </div>
        </div>
        {tokenData?.pendingPaymentAmount > 0
          ? (
            <div style={{ display: isMobile ? 'block' : 'table-cell' }}>
              <div className="vp-label">
                Pending Payment
              </div>
              <div>
                <strong>
                  <Currency value={tokenData?.pendingPaymentAmount}/>
                </strong>
              </div>
            </div>
          )
          : null}
        <div style={{
          display: isMobile ? 'block' : 'table-cell',
          textAlign: 'right',
          verticalAlign: 'middle',
          paddingTop: isMobile ? '30px' : null
        }}>
          <Button onClick={onClick}>
            {actionLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}

const StandardRegistration = ({
  isSkinny
}) => {
  // Hooks
  const dispatch = useDispatch();
  const formRef = useRef();
  const metadata = useRegistrationMetadata();

  const {
    invitationId,
    isValid: isMetadataValid,
    referralToken,
    token
  } = metadata || {};

  // address book is flagged as individual
  // OR company setting to allow this is turned on
  let offerPersonalPortal = metadata?.targetCompanyInfo?.isIndividual
    || metadata?.offerPersonalPortal;

  const [formState, setFormState] = useState({
    companyOrPersonal: metadata?.targetCompanyInfo?.isIndividual ? 'personal' : null,
    companyName: metadata?.companyName,
    email: metadata?.email,
    firstName: metadata?.firstName,
    lastName: metadata?.lastName,
    phone: metadata?.phoneNumber?.length === 10 ? metadata?.phoneNumber : undefined,
    address: metadata?.address
  });

  const {
    coordinatorError,
    error,
    encryptedUserId,
    inExternalTestEnvironment,
    isRegistered,
    isVerified,
    submitLoading
  } = useSelector(
    connectSelector,
    shallowEqual
  );

  const tokenData = useSelector(state => getEntity(state, createEntityReference(token, 'SecureTokenContext'))?.data);
  const [introPassed, setIntroPassed] = useState(false);

  useEffect(
    () => {
      if (isMetadataValid) {
        if (invitationId) {
          Cookies.set('InvitationId', invitationId, { expires: 365, secure: true });
        } else if (referralToken) {
          Cookies.set('ReferralToken', referralToken, { expires: 365, secure: true });
        }
      } else {
        Cookies.remove('InvitationId');
        Cookies.remove('ReferralToken');
      }
    },
    [isMetadataValid, invitationId, referralToken]
  );

  const onCreateAccount = useMemo(
    () => (e) => {
      e.preventDefault();

      let formValue = formRef.current.getValue();

      if (formValue) {
        dispatch(registerCompanyAccount({
          ...formValue,
          isPersonalAccount: formValue?.companyOrPersonal === 'personal',
          token: metadata.token
        }));
      }
    }
  );

  // Render
  const currentError = coordinatorError || error;

  if (!introPassed) {
    return (
      <Intro metadata={metadata}
        tokenData={tokenData}
        onClick={() => setIntroPassed(true)} />
    );
  }

  if (isRegistered && !isVerified) {
    return (
      <VerifyAccount
        canCancel={false}
        encryptedUserId={encryptedUserId}
      />
    );
  }

  let intent = tokenData?.intent;
  let achPlusFee = tokenData?.achPlusFee;
  let achPlusFeeDisplay = tokenData?.achPlusFeeType === 'Percent'
    ? `${achPlusFee}%`
    : <Currency value={achPlusFee} />;
  let companyName = metadata?.from?.companyName;

  return (
    <div style={{ paddingTop: '56px' }}>
      <HeaderText>
        Create Account
      </HeaderText>
      <HeaderText variant={HeaderTextVariants.Small} style={{ paddingTop: '14px', paddingBottom: '20px' }}>
        A Viewpost account enables you to manage payment methods and view payment details.
      </HeaderText>
      {/* {registrationFormHeader} */}
      {inExternalTestEnvironment ? (
        <SmallBannerNotification type={NotificationStyleTypes.Warning}>
          <Messages.TestEnvDisclaimer.Message />
        </SmallBannerNotification>
      ) : null}
      <RegistrationErrorNotification error={currentError} />
      <RegistrationForm
        offerPersonalPortal={offerPersonalPortal}
        invitationId={invitationId}
        isSkinny={isSkinny}
        isSubmitting={submitLoading}
        onChange={setFormState}
        onSubmit={onCreateAccount}
        ref={formRef}
        value={formState}
      />
      <div style={{ paddingTop: '20px', marginTop: '20px', borderTop: '1px solid #ddd'  }}>
        <Messages.Disclaimer.Message
          tou={TermsOfUse}
          paymentTerms={PaymentTerms}
          privacyPolicy={PrivacyPolicy}
        />

        {intent === 'AchPlus' && achPlusFee > 0
          ? (
            <div style={{marginTop: '10px'}}>
              If you have questions about the registration process or applicable
              fees ({achPlusFeeDisplay} on payments from {companyName}),
              please email <a href="mailto:support@viewpost.com">
                Viewpost Customer Support
              </a>.
            </div>
          )
          : null}
      </div>
    </div>
  );
};

export default StandardRegistration;
