/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import FlagIcon from 'components/Icon/FlagIcon';
import Messages from './index.messages';

const usMoneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const getValue = (originalValue, { abbreviateMillions, formatCents }) => {
  let value = originalValue || 0;
  if (isString(value)) {
    value = Number(value);
  }

  const isNegative = value < 0;
  value = Math.abs(value);

  if (abbreviateMillions && value >= 1000000) {
    // Convert to millions
    return {
      isMillions: true,
      value: value * 0.000001,
      isNegative
    };
  } if (formatCents && value > 0 && value < 1) {
    // Round to an integer
    return {
      isCents: true,
      value: Math.floor(value * 100),
      isNegative
    };
  }

  return { isNegative, value };
};

const Currency = ({
  abbreviateMillions,
  formatCents,
  minimumFractionDigits,
  value: originalValue,
  showUsd,
  code
}) => {
  const {
    value,
    isCents,
    isMillions,
    isNegative
  } = getValue(originalValue, { abbreviateMillions, formatCents });

  const FormatComponent = isNegative
    ? Messages.NegativePattern.Message
    : Messages.PositivePattern.Message;

  let formattedNumber = null;

  if (isMillions) {
    formattedNumber = (
      <Messages.Millions.Message
        value={(
          <FormattedNumber value={value}
            style="currency"
            currency={code}
            minimumFractionDigits={minimumFractionDigits}
          />
        )}
      />
    );
  } else if (isCents) {
    formattedNumber = (
      <Messages.Cents.Message
        value={(
          <FormattedNumber value={value}
            style="decimal"
            useGrouping={false}
            minimumFractionDigits={0}
          />
        )}
      />
    );
  } else {

    /*
      We currently only support USD and CAD.
      And we don't like react-intl's default CAN format of: CA$XX
      Nor Intl.formatNumber's intl versions of CAN 123,456.00
      So we're providing our own format here as: $XX CAD or $XX USD
      This is why we're always using an en-US money formatter here for now.
      We should really reconsider this if we start supporting more non-US locales
    */
    formattedNumber = (
      <div style={{display: 'inline-block'}}>
        {usMoneyFormat.format(value) ?? '0.00'} {code !== 'USD' || (showUsd && code === 'USD')
          ? code : null}
      </div>
    );

    // standard 2-digit formatting
    // formattedNumber = (
    //   <FormattedNumber value={value}
    //     style="currency"
    //     currency={code}
    //     minimumFractionDigits={minimumFractionDigits} />
    // );
  }

  return (
    <div style={{display: 'inline-block', whiteSpace: 'nowrap'}}>
      {code === 'CAD'
        ? <><FlagIcon country="CA" />&nbsp;</>
        : null}
      {code === 'USD' && showUsd
        ? <><FlagIcon country="US" />&nbsp;</>
        : null}
      <FormatComponent value={formattedNumber} />
    </div>
  );
};

Currency.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  abbreviateMillions: PropTypes.bool,
  minimumFractionDigits: PropTypes.number,
  code: PropTypes.string
};

Currency.defaultProps = {
  minimumFractionDigits: 2,
  code: 'USD',
  showUsd: false
};

export default Currency;
